

import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator'
import projectMemberApi, { ProjectMember } from '@/api/project-member'

import IndividualMemberList from './individual-member-list.vue'
import GroupMemberList from './group-member-list.vue'

import project, { getterProjectId } from '@/store/index'

@Component({
  name: 'AddProjectMemberModal',
  components: {
    IndividualMemberList,
    GroupMemberList
  }
})
export default class AddProjectMemberModal extends Vue {
    isShow = false

    loading = false

    title = '添加项目成员(提示：列表中已有成员，则已选择的个人成员/组成员不会重复添加)'

    tabName = 'Individual'

    teamId = 0

    selectDataItems: Array<number> = []

    clearCheckboxRow = false

    showDlg () {
      this.isShow = true
      this.teamId = -1
    }

    closeDlg () {
      this.reset()
      this.isShow = false
    }

    reset () {
      this.teamId = 0

      this.selectDataItems = []
      this.clearCheckboxRow = false
    }

    changeTab () {
      this.selectDataItems = []
      this.clearCheckboxRow = true
    }

    /**
   * 全部选中 或 单条选中
   * 列表选中的行
   */
    onSelectionChange (ids: Array<number>) {
      this.selectDataItems = ids
      this.clearCheckboxRow = false
    }

    // 保存选择的数据
    saveData () {
      if (!this.selectDataItems || this.selectDataItems.length === 0) {
        this.$Notice.info(
          {
            title: '提示',
            desc: '没有选择成员'
          }
        )
        return
      }
      this.loading = true
      projectMemberApi.saveProjectMember({
        ids: this.selectDataItems,
        memberType: this.tabName === 'Individual' ? projectMemberApi.Individual : projectMemberApi.Group,
        projectId: getterProjectId()!
      })
        .then(res => {
          this.$Notice.success(
            {
              title: '提示',
              desc: '添加成功'
            }
          )
          this.closeDlg()
          this.$emit('resultProjectMember')
          this.closeDlg()
        })
        .catch((err: Error) => {
          this.$Notice.error({
            title: '提示',
            desc: err.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
}

