

import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator'

import teamUserApi, { TeamUse } from '@/api/team-user'
import projectMember, { ProjectMember } from '@/api/project-member'
import { getterProjectId } from '@/store'

@Component({
  name: 'IndividualMemberList',
  components: { }
})
export default class IndividualMemberList extends Vue {
  tableData: Array<TeamUse> = []

  @Prop({
    type: Number,
    required: false,
    default: null
  })
  teamId!: number

  // 清除选中的行
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  clearCheckboxRow!: boolean

  // 当前已经选择的成员
  selectedMembers: ProjectMember[]=[]

  @Watch('teamId')
  onTeamIdChange () {
    if (this.teamId !== 0) {
      this.doDataList()
    }
  }

  @Watch('clearCheckboxRow')
  onClearCheckboxRow () {
    if (this.clearCheckboxRow) {
      const m = this.$refs.vxeTableIndividualRef as any
      m.clearCheckboxRow()
    }
  }

  doDataList () {
    // 查询已经选择的成员列表
    projectMember.getMemberListByProjectId(getterProjectId()!, projectMember.Individual)
      .then((resp) => {
        this.selectedMembers = resp.data!
        return teamUserApi.curTeamUserList()
      })
      .then(res => {
        this.tableData = (res!.data! as Array<TeamUse>)
          .filter((item) => {
            // 只保留没有选择的成员
            return this.selectedMembers.findIndex((m) => m.memberId === item.userId) === -1
          })
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '错误',
          desc: '取得可选择用户失败：' + err.message
        })
      })
      .finally()
  }

  /**
     * 列表选中行
     * selection.records : Array<any>
     */
  selectionChange (selection: any) {
    this.$emit('selectionChange', (selection.records as Array<any>).map((item) => item.userId))
  }
}

