
import BaseTablePanel from '@/frame/share/table-panel'
import { Component } from 'vue-property-decorator'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import TablePanel from '@/frame/share/table-panel.vue'
import tableConfig from './index-table-config'
import { PagerQueryBean, PageListData, PageDataApiResult } from '@/libs/http-request'
import _ from 'lodash'

import projectMemberApi, { ProjectMember } from '@/api/project-member'

import AddProjectMemberModal from './add-project-member-modal.vue'
import { PageInfo } from '@/libs/api-request'
import { getterProjectId, getterProjectMemberRight } from '@/store'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: ['-modifyTime'],
  memberName: undefined,
  memberType: undefined,
  projectId: getterProjectId()!
}

@Component({
  name: 'projectMemberList',
  components: {
    ExpendSearchPanel,
    TablePanel,
    AddProjectMemberModal
  }
})
export default class ProjectMemberList extends BaseTablePanel<ProjectMember> {
  // 当前状态
  loading=false
  /// 表格定义
  tableConfig=tableConfig

  selectDataItems: Array<ProjectMember> = []

  /**
   * 用户是否可以编辑文档
   */
  get canEdit () {
    const right = getterProjectMemberRight()
    if (!right) return false
    return right.manageProjectMember
  }

  /// 查询数据
  queryData: PagerQueryBean=_.cloneDeep(defaultQuery)

  pageData: PageListData<ProjectMember>={
    items: [],
    total: 0,
    pageSize: 10
  }

  /**
   * 加载数据
   */
  mounted () {
    this.$nextTick(() => {
      this.doPullDataList()
    })
  }

  /**
   * 工具栏按钮事件
   */
  protected doToolBarButtonAction (code: string) {
    this.toolBarButtonAction(code, (this.$refs.tablePanel as any).getTableObject())
  }

  /**
   * 从服务器拉取数据
   */
  protected onPullDataListFromServer (pagerQueryBean: PagerQueryBean): Promise<PageDataApiResult<ProjectMember>> {
    return projectMemberApi.queryProjectMemberList(pagerQueryBean)
  }

  /**
   * 收缩搜索条
   */
  shrinkSeachbar () {
    const newQuery = _.cloneDeep(defaultQuery)
    if (!_.isEqual(newQuery, this.queryData)) {
      this.$nextTick(() => {
        this.queryData = newQuery
        this.doPullDataList()
      })
    }
  }

  /**
   * 页面数据拉取
   */
  protected doPullDataList (pageInfo?: PageInfo) {
    pageInfo = pageInfo || { curPage: 1, pageSize: 10 }

    const that = this

    this.pullDataList(pageInfo).then(response => {
      that.pageData = response;
      (that.$refs.tablePanel as any).setCurPage(pageInfo?.curPage)
    })
  }

  /**
   * 全部选中 或 单条选中
   * 列表选中的行
   */
  onSelectionChange (rows: Array<ProjectMember>) {
    this.selectDataItems = rows
  }

  /**
   * 批量删除
   */
  deleteBatchRows () {
    if (this.selectDataItems.length === 0) {
      this.$Notice.warning({
        title: '提示',
        desc: '请选择您要删除记录'
      })
      return
    }
    this.$Modal.confirm(
      {
        title: '提示',
        content: '已选中' + this.selectDataItems.length + '条数据,确定要删除吗？',
        onOk: () => {
          const ids: Array<number> = this.selectDataItems.map(item => {
            return item!.id!
          })
          this.deleteData(ids)
        },
        onCancel: () => {
          console.log()
        }
      }
    )
  }

  deleteRow (row: ProjectMember, index: number) {
    this.$Modal.confirm(
      {
        title: '提示',
        content: '确定要删除吗？',
        onOk: () => {
          this.deleteData([row.id!])
        },
        onCancel: () => {
          console.log()
        }
      }
    )
  }

  deleteData (ids: Array<number>) {
    projectMemberApi.deleteItemByIds(ids)
      .then(res => {
        this.$Notice.success({ title: '提示', desc: '删除成功' })
        // 刷新列表
        this.doPullDataList()
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '删除记录失败',
          desc: err.message
        })
      })
      .finally(() => this.finishLoading())
  }

  /**
   * 修改数据模型
   */
  updateDataModelChange (row: ProjectMember) {
    this.projectMemberPermission(row.id!, row.updateDataModel!, projectMemberApi.updateDataModel)
  }

  /**
   * 修改项目信息
   */
  updateProjectInfoChange (row: ProjectMember) {
    this.projectMemberPermission(row.id!, row.updateProjectInfo!, projectMemberApi.updateProjectInfo)
  }

  /**
   * 修改api接口
   */
  updateApiInterfaceChange (row: ProjectMember) {
    this.projectMemberPermission(row.id!, row.updateApiInterface!, projectMemberApi.updateApiInterface)
  }

  /**
   * 管理项目成员
   */
  manageProjectMemberChange (row: ProjectMember) {
    this.projectMemberPermission(row.id!, row.manageProjectMember!, projectMemberApi.manageProjectMember)
  }

  codeGenerMemberChange (row: ProjectMember) {
    this.projectMemberPermission(row.id!, row.codeGener!, projectMemberApi.codeGener)
  }

  projectMemberPermission (id: number, disable: boolean, flag: string) {
    projectMemberApi.projectMemberPermission(id, disable, flag)
      .then(res => {
        this.$Notice.success({ title: '提示', desc: '修改成功' })
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '错误',
          desc: err.message
        })
      })
      .finally(() => this.finishLoading())
  }

  /**
   * 添加项目成员
   */
  addProjectMember () {
    const modal = this.$refs.addProjectMemberModalRef as AddProjectMemberModal
    modal.showDlg()
  }

  resultProjectMember () {
    this.doPullDataList()
  }
}

