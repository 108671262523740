

import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator'

import teamGroupApi, { TeamGroup } from '@/api/team-group'
import projectMember, { ProjectMember } from '@/api/project-member'
import { getterProjectId } from '@/store'

@Component({
  name: 'GroupMemberList',
  components: { }
})
export default class GroupMemberList extends Vue {
  tableData: Array<TeamGroup> = []

  @Prop({
    type: Number,
    required: false,
    default: null
  })
  teamId!: number

  // 清除选中的行
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  clearCheckboxRow!: boolean

  // 当前已经选择的成员
  selectedMembers: ProjectMember[]=[]

  @Watch('teamId')
  onTeamIdChange () {
    if (this.teamId !== 0) {
      this.doDataList(this.teamId)
    }
  }

  @Watch('clearCheckboxRow')
  onClearCheckboxRow () {
    if (this.clearCheckboxRow) {
      const m = this.$refs.vxeTableGroupRef as any
      m.clearCheckboxRow()
    }
  }

  doDataList (teamId: number) {
    projectMember.getMemberListByProjectId(getterProjectId()!, projectMember.Group)
      .then((resp) => {
        this.selectedMembers = resp.data!
        return teamGroupApi.teamGroupList()
      })
      .then(res => {
        this.tableData = (res!.data! as Array<TeamGroup>)
          .filter((item) => {
            // 只保留没有选择的成员
            return this.selectedMembers.findIndex((m) => m.memberId === item.id) === -1
          })
      })
      .catch((err: Error) => {
        this.$Notice.error({
          title: '提示',
          desc: err.message
        })
      })
      .finally()
  }

  /**
     * 列表选中行
     * selection.records : Array<any>
     */
  selectionChange (selection: any) {
    this.$emit('selectionChange', selection.records.map((item: any) => item.id))
  }
}

